.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #4a5568; /* 使用线条样式的箭头 */
  transition: transform 0.3s ease; /* 添加过渡效果 */
}

details[open] .arrow {
  transform: rotate(90deg); /* 展开时旋转箭头 */
}
